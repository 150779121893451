import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Slide from "react-reveal/Slide";
import Layout from "../components/layout"
import Seo from "../components/seo"
import WeWorkWith from "../components/WeWorkWith"

const About = ({ data, location }) => {
  const { t, i18n } = useTranslation()

  const brand = (i18n.language === "tr" ? data.site?.siteMetadata?.titleTr : data.site?.siteMetadata?.titleEn) || `tbant`

  const schema = {
    "@context": "https://schema.org/",
    "@type": "Organization",
    "name": brand,
    "url": data.site.siteMetadata.siteUrl + location.pathname,
    "logo": "https://tbantsan.com/static/4681537f4a6b060de47d5cafd10d982b/b9a68/logo-dark.png",
    "image": "https://tbantsan.com/static/4681537f4a6b060de47d5cafd10d982b/b9a68/logo-dark.png",
    "email": "iletisim@tbantsan.com",
    "description": t("aboutUsDescription1"),
    "address": "Tuzla Deri OSB Vakum Cad. No:13/A\nÇ3-3 34957 Tuzla / İstanbul",
    "telephone": "+90 216 394 83 70",
    "sameAs": "https://www.linkedin.com/company/tbantsan/",
    "hasOfferCatalog": ""
  }

  const seo = {
    title: t("metaAboutTitle"),
    description: t("metaAboutDescription"),
    keywords: t("metaAboutKeywords"),
    schema: schema
  }

  return (
    <Layout className="bg-reallyDarkBlue">
      <Seo {...seo} />
      <div className="bg-reallyDarkBlue text-white relative py-10">
        <div className="max-w-7xl mx-auto p-10">
          <Slide bottom duration={1000}>
            <h1 className="text-4xl lg:text-6xl w-max font-bold mx-auto">
              {t("aboutUs")}
            </h1>
          </Slide>
          <div className="md:flex justify-between py-16 lg:py-24">
            <div className="md:w-2/5 mb-10">
              <Slide bottom duration={1000}>
                <p className="md:text-xs lg:text-lg font-light whitespace-pre-line">
                  {t("aboutUsDescription1")}
                </p>
              </Slide>
            </div>
            <div className="md:w-1/2">
              <StaticImage src="../images/about-hero.png" alt="about hero" />
            </div>
          </div>
          <div className="md:w-1/2 mx-auto py-5 md:py-10 lg:py-28">
            <Slide bottom duration={1000}>
              <p className="md:text-xs lg:text-lg font-light whitespace-pre-line">
                {t("aboutUsDescription2")}
              </p>
            </Slide>
          </div>
        </div>
        <div className="relative">
          <div className="w-full my-32">
            <div className="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full mx-auto">
              <Slide bottom duration={1000}>
                <p className="text-white text-4xl md:text-6xl text-center font-bold">{t("20yearsofExperience")}</p>
              </Slide>
            </div>
            <StaticImage
              src="../images/years20.png"
              alt="+ 20 years of experience"
            />
          </div>
        </div>
        <WeWorkWith />
      </div>
    </Layout>
  )
}


export default About

export const query = graphql`
   query ($language: String!){
    locales: allLocale(filter: { language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTr
        titleEn
        siteUrl
      }
    }
  }
`

